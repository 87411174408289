import { ReactKeycloakProvider } from '@react-keycloak/web'
import { Router } from '@routes/Router'
import { handleReceivingTokens } from '@services/auth'
import { AdWordKeycloakProvider } from '@hooks/useKeycloack'
import { AdWordProvider } from '@hooks/useAdWord'
import { keycloak, keycloakProviderInitConfig } from '@configs/keycloak/auth'

export function App() {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakProviderInitConfig}
      onTokens={handleReceivingTokens}
      autoRefreshToken
    >
      <AdWordKeycloakProvider>
        <AdWordProvider>
          <Router />
        </AdWordProvider>
      </AdWordKeycloakProvider>
    </ReactKeycloakProvider>
  )
}
