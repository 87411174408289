import {
  Flex, IconFeatured, Text, Button
} from '@memed/epiderme'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { INITIAL_STATE, useAdWordStore } from '@store/useAdWordStore'
import { useCartStore } from '@store/useCartStore'
import { useMoleculeStore } from '@store/useMoleculesStore'
import { scrollTo } from '@utils/scrollTo'

const CardWrapper = ({ children }: { children: ReactNode }) => (
  <Flex
    border="1px"
    borderColor="neutral.200"
    borderRadius="8px"
    flexDir="column"
    alignItems="center"
    p={6}
    gap={6}
  >
    {children}
  </Flex>
)

export const Success = () => {
  const clearMolecules = useMoleculeStore((state) => state.clear)
  const setStorePresentation = useAdWordStore((state) => state.setPresentation)
  const setType = useAdWordStore((state) => state.setType)
  const clearCart = useCartStore((state) => state.clear)

  const navigate = useNavigate()

  const handleNewReserve = () => {
    setStorePresentation(INITIAL_STATE.presentation)
    clearMolecules()
    clearCart()
    setType(INITIAL_STATE.type)
    navigate('/')
    scrollTo(60)
  }

  return (
    <Flex flexDir="column" gap={4}>
      <CardWrapper>
        <Flex flexDir="column" alignItems="center" gap={2}>
          <IconFeatured icon="circle-check" size={20} colorScheme="success" />
          <Text color="success.600" fontWeight="medium" lineHeight="base">Reserva confirmada!</Text>
        </Flex>
        <Flex flexDir="column" gap={6}>
          <Text color="neutral.800">
            Sua reserva no nosso catálogo do destaque está confirmada.
          </Text>
          <Text>
            Obrigado (a)!
          </Text>
        </Flex>
        <Flex gap={6}>
          <Button size="sm" px={4} onClick={handleNewReserve}>Nova contratação</Button>
        </Flex>
      </CardWrapper>
    </Flex>
  )
}
