import { Box } from '@memed/epiderme'
import { Outlet } from 'react-router-dom'
import { Navbar } from '@components/Navbar'

export function DefaultLayout() {
  return (
    <Box
      minH="100vh"
      bg="neutral.50"
    >
      <Navbar />
      <Outlet />
    </Box>
  )
}
