/* eslint-disable no-param-reassign */
import { create } from 'zustand'
import produce from 'immer'
import { IHarvestErrors } from '@interfaces/Harvest'

export interface IStore {
  harvestsErrors: IHarvestErrors[];
  setHarvestErrors: (harvestsErrors: IHarvestErrors[]) => void
  actions: {
    clear: () => void
  }
}

const INITIAL_STATE = {
  harvestsErrors: [] as IHarvestErrors[]
}

export const useHarvestsErrorsStore = create<IStore>((set) => {
  const setState = (fn: (state: IStore) => void) => set(produce(fn))

  return {
    harvestsErrors: INITIAL_STATE.harvestsErrors,
    setHarvestErrors(harvestsErrors: IHarvestErrors[]) {
      setState((state) => {
        state.harvestsErrors = [...harvestsErrors]
      })
    },
    actions: {
      clear() {
        setState((state) => {
          state.harvestsErrors = []
        })
      }
    }
  }
})
