import styled from '@emotion/styled'
import theme, { MenuItem as MenuItemEpiderme } from '@memed/epiderme'

export const MenuItem = styled(MenuItemEpiderme)`

  &.custom-menu-item > span {
    display: none;
  }

  &.custom-menu-item.logout > div {
    color: ${theme.colors.danger[500]};
  }

`
