import { IMolecule } from 'src/interfaces/Molecule'
import { create } from 'zustand'

export interface IStore {
  molecules: IMolecule[];
  add: (molecule: IMolecule) => void;
  remove: (molecule: IMolecule) => void;
  set: (molecules: IMolecule[]) => void
  clear: () => void;
}

export const INITIAL_STATE = {
  molecules: []
}

export const useMoleculeStore = create<IStore>()((set) => ({
  molecules: INITIAL_STATE.molecules,
  add: (molecule) => set((state) => ({ molecules: [...state.molecules, molecule] })),
  remove: (molecule) => set((state) => ({ molecules: state.molecules.filter((mol) => mol.id !== molecule.id) })),
  set: (molecules) => set(() => ({ molecules })),
  clear: () => set(() => ({ molecules: INITIAL_STATE.molecules }))
}))
