import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Box, Flex, Icon, Text
} from '@memed/epiderme'
import { ICart } from '@interfaces/Cart'
import { formatDate } from '@utils/dateTime'
import { useCartStore } from '@store/useCartStore'
import { useNavigate } from 'react-router-dom'
import { useAdWordStore } from '@store/useAdWordStore'
import { useMoleculeStore } from '@store/useMoleculesStore'
import { Divider } from '@chakra-ui/react'
import { useHarvestsErrorsStore } from '@store/useHarvestsErrorsStore'

interface ICartItem {
  item: ICart
}

export const CartItem = ({ item }: ICartItem) => {
  const setPresentation = useAdWordStore((state) => state.setPresentation)
  const setType = useAdWordStore((state) => state.setType)
  const setMolecules = useMoleculeStore((state) => state.set)
  const removeCartItem = useCartStore((state) => state.remove)
  const harvestsErrors = useHarvestsErrorsStore((state) => state.harvestsErrors)
  const molecules = item.compositions.map((composition) => composition.name)
  const seasonData = item.data
  const navigate = useNavigate()

  const hasHarvestErrors = (
    harvest: { id: number, position: number }
  ) => {
    const harvestError = harvestsErrors.find((error) => error.external_id === `${item.presentation.id}`)
    if (harvestError) {
      return harvestError.harvests.some((error) => error.id === harvest.id && error.position === harvest.position)
    }
    return false
  }

  const getDataBySeasonId = (seasonId: number, positionId: number) => {
    const season = seasonData.find((data) => data.id === seasonId)
    const data = season?.positions.find((pos) => pos.id === positionId)
    return data
  }

  const getTotalValue = () => {
    const prices = item.harvests.map((harvest) => getDataBySeasonId(harvest.id, harvest.position))
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return prices.reduce((a, b) => a + (b?.value || 0), 0)
  }

  const getHarvestNameById = (seasonId: number) => seasonData.find((data) => data.id === seasonId)?.name || ''

  const getStartedAtByHarvestId = (seasonId: number) => seasonData.find((data) => data.id === seasonId)?.startedAt || ''

  const getEndedAtByHarvestId = (seasonId: number) => seasonData.find((data) => data.id === seasonId)?.endedAt || ''

  const removeItemByTimestamp = (timestamp: number) => removeCartItem(timestamp)

  const editCartItemByTimestamp = (timestamp: number) => {
    setPresentation(item.presentation)
    setType(item.ingredient_type)
    setMolecules(item.molecules)
    navigate(`/edit/${timestamp}`)
  }

  return (
    <>
      <Flex justifyContent="space-between" mb={2}>
        <Flex minW="264px" flexDir="column" justifyContent="space-around">
          <Text as="p" fontSize="14px" color="neutral.600" lineHeight="150%">{molecules.join(' + ')}</Text>
          <Text as="p" fontSize="14px" color="neutral.600" lineHeight="150%">
            {`${item.name}, ${item.description}`}
          </Text>
        </Flex>
        <Flex minW="188" h="60px" alignItems="start" justifyContent="space-between" gap={4}>
          <Text as="p" fontSize="14px" color="neutral.600" lineHeight="150%" mt="2px" minW="60px">
            {getTotalValue().toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          </Text>
          <Flex justifyContent="end" gap="4">
            <Box onClick={() => editCartItemByTimestamp(item.timestamp)} cursor="pointer">
              <Icon icon="document-note" color="neutral.600" />
            </Box>
            <Box onClick={() => removeItemByTimestamp(item.timestamp)} cursor="pointer">
              <Icon icon="garbage-bin" color="neutral.600" />
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Flex border="1px" borderColor="neutral.300" borderRadius={4} mb={6}>
        <Accordion width="100%">
          <AccordionItem>
            <AccordionHeader>
              <Text
                fontWeight="medium"
                fontSize="sm !important"
              >
                Ciclos e posições (
                {item.harvests.length}
                )
              </Text>
            </AccordionHeader>
            <AccordionPanel>
              {item.harvests.map((harvest) => (
                <Flex
                  justifyContent="space-between"
                  height="1.25rem"
                  key={`${getHarvestNameById(harvest.id)}}`}
                  mt={2}
                >
                  <Flex width="100%" alignItems="center" justifyContent="space-between">
                    <Text
                      fontSize="sm"
                      {...(hasHarvestErrors(harvest) && { color: 'danger.500' })}
                    >
                      {getHarvestNameById(harvest.id)}
                      :
                      {' '}
                      {formatDate(getStartedAtByHarvestId(harvest.id))}
                      {' '}
                      a
                      {' '}
                      {formatDate(getEndedAtByHarvestId(harvest.id))}
                    </Text>
                  </Flex>
                  <Flex width="35%">
                    <Text
                      fontSize="sm"
                      {...(hasHarvestErrors(harvest) && { color: 'danger.500' })}
                    >
                      {getDataBySeasonId(harvest.id, harvest.position)?.name}

                    </Text>
                  </Flex>
                  <Flex width="25%">
                    <Text
                      fontSize="sm"
                      {...(hasHarvestErrors(harvest) && { color: 'danger.500' })}
                    >
                      {getDataBySeasonId(harvest.id, harvest.position)?.value
                        .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
      <Divider mb={3} />
    </>
  )
}
