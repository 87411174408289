import { Flex, Text } from '@memed/epiderme'
import { useUserProfileStore } from '@store/useUserProfileStore'
import { ReactNode } from 'react'
import { Accordion } from '..'

interface IWrapper {
  children: ReactNode
}

const FieldWrapper = ({ children }: IWrapper) => <Flex flexDir="column" gap={2}>{children}</Flex>

const Label = ({ children }: IWrapper) => <Text fontWeight="medium" fontSize="sm">{children}</Text>

const Value = ({ children }: IWrapper) => <Text fontSize="md">{children}</Text>

export const ExecutiveAccordion = () => {
  const user = useUserProfileStore((state) => state.userProfile)

  return (
    <Accordion title="Dados executivo (a) Memed">
      <Flex py={6} borderTop="1px" borderColor="neutral.300">
        <Flex flexDir="column" gap={6} w="50%">
          <FieldWrapper>
            <Label>ID do Pipedrive:</Label>
            <Value>{user.pipdriveId}</Value>
          </FieldWrapper>
        </Flex>
        <Flex flexDir="column" gap={6} w="50%">
          <FieldWrapper>
            <Label>E-mail Memed:</Label>
            <Value>{user.email}</Value>
          </FieldWrapper>
        </Flex>
      </Flex>
    </Accordion>
  )
}
