import {
  Accordion as EpidermeAccordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Text
} from '@memed/epiderme'
import { ReactNode } from 'react'

interface IAccordion {
  children: ReactNode
  title: string
}

export const Accordion = ({ children, title }: IAccordion) => (
  <EpidermeAccordion border="1px" borderColor="neutral.300" py={4} borderRadius="8px" mb={6}>
    <AccordionItem>
      <AccordionHeader>
        <Text fontWeight="medium" fontSize="md !important">{title}</Text>
      </AccordionHeader>
      <AccordionPanel>
        {children}
      </AccordionPanel>
    </AccordionItem>
  </EpidermeAccordion>
)
