import { ICart } from '@interfaces/Cart'
import { create } from 'zustand'

export interface IStore {
  cart: ICart[]
  clear: () => void
  add: (item: ICart) => void
  remove: (timestamp: number) => void
}

const INITIAL_STATE = {
  cart: []
}

export const useCartStore = create<IStore>()((set) => ({
  cart: INITIAL_STATE.cart,
  add: (item) => set((state) => ({ cart: [...state.cart, item] })),
  remove: (timestamp) => set((state) => ({ cart: state.cart.filter((item) => item.timestamp !== timestamp) })),
  clear: () => set(() => ({ cart: INITIAL_STATE.cart }))
}))
