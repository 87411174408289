import theme, {
  Box, Text, Card, Heading
} from '@memed/epiderme'
import { Outlet } from 'react-router-dom'

type DefaultContainerProps = {
  title: string
  subtitle?: string
}
export const DefaultContainer = ({ title, subtitle }: DefaultContainerProps) => (
  <Box
    m="3.5rem auto 0 auto"
    pt="4.5rem"
    maxW="58.5rem"
    display="flex"
    flexDirection="column"
    alignItems="center"
  >
    <Box
      w="46.875rem"
      display="flex"
      flexDirection="column"
      alignItems="start"
    >
      <Heading
        as="h1"
        mb="1rem"
        type="title-md"
        letterSpacing="tight"
        fontWeight="medium"
        color="neutral.700"
        lineHeight="shorter"
      >
        {title}
      </Heading>
      {subtitle && (
        <Text
          as="p"
          fontWeight="normal"
          lineHeight="base"
          color="neutral.700"
          fontSize="md"
        >
          {subtitle}
        </Text>
      )}
    </Box>
    <Card
      as="div"
      w="100%"
      m="2rem 0 4rem 0"
      p="4rem 3.5rem"
      boxShadow={theme.shadows.md}
    >
      <Box w="46.875rem" marginX="auto">
        <Outlet />
      </Box>
    </Card>
  </Box>
)
