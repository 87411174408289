/* eslint-disable react-hooks/exhaustive-deps */
import { Center } from '@chakra-ui/react'
import { SearchWrapper } from '@components/SearchWrapper'
import { IIndustry } from '@interfaces/Industry'
import { Box, InputText, Text } from '@memed/epiderme'
import IndustryRepository from '@services/api/IndustryRepository'
import KeycloakUserRepository from '@services/api/keycloakUserRepository'
import { useUserFormDataStore } from '@store/useUserFormDataStore'
import { useUserProfileStore } from '@store/useUserProfileStore'
import {
  ChangeEvent, useEffect, useState
} from 'react'
import { IndustryItem } from './IndustryItem'
import { IndustryTag } from './IndustryTag'
import { IIndustriesSearch } from './interfaces'

export const IndustriesSearch = ({
  isDisabled
}: IIndustriesSearch) => {
  const [industries, setIndustries] = useState<IIndustry[]>([])
  const [filteredIndustries, setFilteredIndustries] = useState<IIndustry[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [showDropdown, setShowDropdown] = useState(false)

  const { userProfile } = useUserProfileStore((state) => state)
  const { setUserProfile } = useUserProfileStore((state) => state.actions)
  const { userFormData } = useUserFormDataStore((state) => state)
  const { addIndustry, removeIndustry } = useUserFormDataStore((state) => state.actions)

  const getBySearchTerm = (term: string) => {
    if (industries && industries.length) {
      setFilteredIndustries(
        industries.filter(({ name }) => name.toLowerCase().includes(term.toLowerCase()))
      )
    }
    setShowDropdown(true)
  }

  const handleClickIndustry = (industry: IIndustry) => {
    const alreadyOnList = (userFormData.industries ?? [])
      .find(({ id }) => id === industry.id)

    setShowDropdown(false)
    setSearchTerm('')

    if (alreadyOnList) return
    addIndustry(industry)
  }

  const getIndustries = async () => {
    try {
      const response = await IndustryRepository.list()
      if (!response) {
        throw new Error('Industries not found')
      }
      setIndustries(response)
    } catch (error) {
      console.error(error)
    }
  }

  const getUser = async () => {
    if (userProfile.email) {
      return
    }

    const { id, email, attributes } = await KeycloakUserRepository.show()
    setUserProfile({
      email,
      id,
      ...attributes
    })
  }

  useEffect(() => {
    getIndustries()
    getUser()
  }, [])

  useEffect(() => {
    if (!searchTerm) {
      setShowDropdown(false)
      setFilteredIndustries(industries)
      return
    }
    getBySearchTerm(searchTerm)
  }, [industries, searchTerm])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const hasIndustriesFilteredResults = filteredIndustries && filteredIndustries.length > 0

  return (
    <Box position="relative">
      <InputText
        label="Indústria (s)"
        iconLeft="search-loupe"
        placeholder="Busque por indústrias do mesmo grupo"
        isRequired={!userFormData.industries?.length}
        isDisabled={isDisabled}
        value={searchTerm}
        onChange={handleChange}
      />
      <SearchWrapper showDropdown={showDropdown} onClose={setShowDropdown}>
        {hasIndustriesFilteredResults && filteredIndustries.map((industry) => (
          <IndustryItem industry={industry} handleClick={handleClickIndustry} key={industry.id} />
        ))}
        {!hasIndustriesFilteredResults && (
          <Center mt={2} py={2}>
            <Text fontSize="sm">Não há resultados para exibir</Text>
          </Center>
        )}
      </SearchWrapper>
      <Box mt={2} minH="1.75rem" display="flex" gap="3" flexWrap="wrap">
        {userFormData.industries?.map((industry: IIndustry) => (
          <IndustryTag
            isDisabled={isDisabled}
            industry={industry}
            handleClick={removeIndustry}
            key={industry.id}
          />
        ))}
      </Box>
    </Box>
  )
}
