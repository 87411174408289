import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'
import theme from '@memed/epiderme'

export const Header = styled(Box)`
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  box-shadow: ${theme.shadows.base};
  background-color: white;
  border-bottom: ${theme.borders['1px']};
  border-bottom-color: ${theme.colors.neutral['300']};
`
