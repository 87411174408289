import { ICart } from '@interfaces/Cart'
import { IHarvestResponse } from '@interfaces/Harvest'
import { IIngredientType } from '@interfaces/IngredientType'
import { IMolecule } from '@interfaces/Molecule'
import { api } from '@services/api'
import { AxiosResponse } from 'axios'

type IAxiosMoleculesResponse = AxiosResponse<{ harvests: IHarvestResponse[] }>

interface IGetAvailability {
  compositions: IMolecule[],
  type: IIngredientType
  external_id: string
}

class AdsRepository {
  private static harvestMapper(data: IHarvestResponse[]) {
    return data.map((harvest) => ({
      id: harvest.id,
      available: harvest.available,
      startedAt: harvest.start_at,
      endedAt: harvest.expires_at,
      name: harvest.harvest_name,
      positions: harvest.positions
    }))
  }

  private static adsMapper(cart: ICart[]) {
    return cart.map((harvest) => ({
      external_id: String(harvest.presentation.id),
      ean: harvest.presentation.ean ?? null,
      type: harvest.type,
      user_id: harvest.user_id,
      harvests: harvest.harvests,
      compositions: harvest.molecules.map(({ id, nome: name }) => ({ id, name })),
      ingredient_type: harvest.ingredient_type
    }))
  }

  public static async getAvailability({ compositions, type, external_id }: IGetAvailability) {
    const compositionsQuery = compositions.map((composition) => `compositions=${composition.id}`)
    const response: IAxiosMoleculesResponse = await api.get(
      `/ads/is-available?external_id=${external_id}&ingredient_type=${type}&${compositionsQuery.join('&')}`
    )
    return this.harvestMapper(response.data.harvests)
  }

  public static async post(cart: ICart[]) {
    const response: IAxiosMoleculesResponse = await api.post('/ads/', this.adsMapper(cart))
    return response.data.harvests
  }
}

export default AdsRepository
