import { IIngredientType } from '@interfaces/IngredientType'
import { IMolecule } from '@interfaces/Molecule'
import { IPresentation } from '@interfaces/Presentation'
import { api } from '@services/api'
import { AxiosResponse } from 'axios'

type IAxiosPresentationResponse = AxiosResponse<IPresentation[]>

interface IGetByName {
  name: string,
  compositions: IMolecule[],
  type: IIngredientType,
}

class PresentationRepository {
  public static async get({ name, compositions, type }: IGetByName) {
    const compositionsQuery = compositions.map((composition) => `compositions=${composition.id}`)

    const response: IAxiosPresentationResponse = await api.get(
      `/presentation?${compositionsQuery.join('&')}&nome=${name}&ingredientType=${type}`
    )
    return response.data
  }
}

export default PresentationRepository
