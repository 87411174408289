import { IIndustry } from '@interfaces/Industry'
import { Box, Text } from '@memed/epiderme'

interface IIndustryItem {
  industry: IIndustry
  handleClick: (industry: IIndustry) => void
}

export const IndustryItem = ({ industry, handleClick }: IIndustryItem) => (
  <Box
    as="button"
    type="button"
    paddingY={2}
    paddingX={3}
    cursor="pointer"
    display="flex"
    gap={3}
    marginTop={2}
    key={industry.id}
    width="100%"
    _hover={{
      background: 'primary.50'
    }}
    onClick={() => handleClick(industry)}
  >
    <Text lineHeight="base" color="gray.700" fontSize="sm">{industry.name}</Text>
  </Box>
)
