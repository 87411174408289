import { IIndustry } from '@interfaces/Industry'
import {
  Alert, Box, Flex, Tag, Text
} from '@memed/epiderme'
import { useUserProfileStore } from '@store/useUserProfileStore'
import { ReactNode } from 'react'
import { Accordion } from '..'

interface IWrapper {
  children: ReactNode
}

const FieldWrapper = ({ children }: IWrapper) => <Flex flexDir="column" gap={2}>{children}</Flex>

const Label = ({ children }: IWrapper) => <Text fontWeight="medium" fontSize="sm">{children}</Text>

const Value = ({ children }: IWrapper) => <Text fontSize="md">{children}</Text>

export const ClientAccordion = () => {
  const user = useUserProfileStore((state) => state.userProfile)

  return (
    <Accordion title="Dados contratuais do cliente">
      <Flex py={6} borderTop="1px" borderColor="neutral.300">
        <Flex flexDir="column" gap={6} w="50%">
          <FieldWrapper>
            <Label>CNPJ do contrato:</Label>
            <Value>{user.cnpj}</Value>
          </FieldWrapper>
          <FieldWrapper>
            <Label>Razão Social:</Label>
            <Value>{user.corporateName}</Value>
          </FieldWrapper>
          <FieldWrapper>
            <Label>Indústria (s):</Label>
            <Box mt={2} minH="1.75rem" display="flex" gap="3" flexWrap="wrap">
              {user.industries.map((industry: IIndustry) => (
                <Tag
                  color="secondary"
                  userSelect="none"
                  variant="light"
                  key={industry.id}
                >
                  {industry.name}
                </Tag>
              ))}
            </Box>
          </FieldWrapper>
        </Flex>
        <Flex flexDir="column" gap={6} w="50%">
          <FieldWrapper>
            <Label>Nome da pessoa contratante:</Label>
            <Value>{user.customer}</Value>
          </FieldWrapper>
          <FieldWrapper>
            <Label>Telefone:</Label>
            <Value>{user.telephone}</Value>
          </FieldWrapper>
        </Flex>
      </Flex>
      <Alert status="warning">
        Lembre-se de conferir o CNPJ e razão social da indústria.
        Esses dados irão constar no contrato e na nota fiscal da contratação.
      </Alert>
    </Accordion>
  )
}
