import {
  Button, Flex, Switch, Text
} from '@memed/epiderme'
import { INITIAL_STATE, useAdWordStore } from '@store/useAdWordStore'
import { useCartStore } from '@store/useCartStore'
import { useMoleculeStore } from '@store/useMoleculesStore'
import { useUserProfileStore } from '@store/useUserProfileStore'
import { scrollTo } from '@utils/scrollTo'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const FormControls = () => {
  const [isChecked, setIsChecked] = useState(false)
  const selectedHarvest = useAdWordStore((state) => state.selectedHarvest)
  const setSelectedHarvest = useAdWordStore((state) => state.setSelectedHarvest)
  const harvests = useAdWordStore((state) => state.harvests)
  const molecules = useMoleculeStore((state) => state.molecules)
  const presentation = useAdWordStore((state) => state.presentation)
  const setPresentation = useAdWordStore((state) => state.setPresentation)
  const clearMolecules = useMoleculeStore((state) => state.clear)
  const ingredientType = useAdWordStore((state) => state.type)
  const setIngredientType = useAdWordStore((state) => state.setType)
  const addToCart = useCartStore((state) => state.add)
  const removeFromCart = useCartStore((state) => state.remove)
  const userProfile = useUserProfileStore((state) => state.userProfile)
  const navigate = useNavigate()

  const { timestamp } = useParams()

  const handleKeepMolecule = () => setIsChecked((state) => !state)

  const addItemsToCart = () => {
    setSelectedHarvest([])
    const harvestsPositions = selectedHarvest.map((harvest) => ({
      position: harvest.positionId,
      id: harvest.seasonId
    }))

    const harvestData = selectedHarvest.map((harvest) => harvests[harvest.seasonId - 1])

    const mapper = () => ({
      user_id: userProfile.id,
      ean: presentation.ean,
      type: presentation.type,
      harvests: harvestsPositions,
      compositions: presentation.compositions,
      ingredient_type: ingredientType,
      name: presentation.name,
      description: presentation.description,
      data: harvestData,
      presentation,
      molecules,
      timestamp: Date.now()
    })
    const item = mapper()
    addToCart(item)
  }

  const handleClickReserve = () => {
    addItemsToCart()
    setPresentation(INITIAL_STATE.presentation)
    navigate('/cart')
    scrollTo(60)
  }

  const handleClickContinue = () => {
    handleKeepMolecule()
    addItemsToCart()
    setPresentation(INITIAL_STATE.presentation)
    scrollTo(60)
  }

  const handleClickNewProduct = () => {
    addItemsToCart()
    setIngredientType(INITIAL_STATE.type)
    clearMolecules()
    setPresentation(INITIAL_STATE.presentation)
    scrollTo(60)
  }

  const handleClickSave = () => {
    if (timestamp) {
      removeFromCart(+timestamp)
      addItemsToCart()
      scrollTo(60)
      navigate('/cart')
    }
  }

  const shouldDisableFormControls = () => {
    const hasHarvestSelected = selectedHarvest
      .every((selected) => selected.checked && selected.seasonId && selected.positionId)
    if (presentation && molecules && hasHarvestSelected && selectedHarvest.length) {
      return false
    }
    return true
  }

  return timestamp ? (
    <Flex gap={4} justifyContent="flex-end">
      <Button size="sm" onClick={handleClickSave} isDisabled={shouldDisableFormControls()}>Salvar alterações</Button>
    </Flex>
  ) : (
    <Flex justifyContent="space-between" alignItems="center">
      <Switch size="md" isChecked={isChecked} onChange={handleKeepMolecule}>
        <Text fontSize="sm">Manter molécula e inserir nova marca</Text>
      </Switch>
      <Flex transition="0.2s">
        {isChecked ? (
          <Button
            size="sm"
            onClick={handleClickContinue}
            disabled={shouldDisableFormControls()}
          >
            Continuar
          </Button>
        ) : (
          <Flex gap={4}>
            <Button
              size="sm"
              variant="outline"
              onClick={handleClickNewProduct}
              disabled={shouldDisableFormControls()}
            >
              Reservar novo produto
            </Button>
            <Button
              size="sm"
              onClick={handleClickReserve}
              disabled={shouldDisableFormControls()}
            >
              Solicitar reserva
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
