/* eslint-disable no-useless-catch */
import { IIndustry } from '@interfaces/Industry'
import { api } from '@services/api'
import { AxiosResponse } from 'axios'

type IAxiosIndustriesResponse = AxiosResponse<IIndustry[]>

class IndustryRepository {
  public static async list() {
    const response: IAxiosIndustriesResponse = await api.get('/industry')
    return response.data
  }
}

export default IndustryRepository
