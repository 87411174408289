/* eslint-disable react-hooks/exhaustive-deps */
import {
  Menu, MenuButton, MenuList, MenuDivider, Button
} from '@chakra-ui/react'
import theme, { Box, Icon } from '@memed/epiderme'
import { useKeycloak } from '@react-keycloak/web'
import { scrollTo } from '@utils/scrollTo'
import { useLocation, useNavigate } from 'react-router-dom'
import { MenuItem } from './styles'
import { Username } from './Username'

export function UserMenu() {
  const navigate = useNavigate()
  const { keycloak } = useKeycloak()
  const { pathname } = useLocation()

  return (
    <Menu>
      {({ onClose }) => (
        <>
          <MenuButton
            as={Button}
            variant="ghost"
            colorScheme="white"
            rightIcon={(
              <Box
                marginLeft="1rem"
              >
                <Icon icon="chevron-down" color="neutral.500" />
              </Box>
            )}
            cursor="pointer"
            h={theme.sizes['8']}
          >
            <Username />
          </MenuButton>
          <MenuList
            mt="0.3rem"
            ml="0.3rem"
            padding={0}
            w="15rem"

          >
            <MenuItem
              onClick={() => {
                onClose()
                navigate('/profile')
                scrollTo(60)
              }}
              label="Configurações"
              isActive={pathname.includes('/profile')}
              borderEndEndRadius={0}
              borderEndStartRadius={0}
              className="custom-menu-item"
            />
            <MenuDivider m={0} borderWidth={2} />
            <MenuItem
              onClick={() => {
                onClose()
                keycloak?.logout()
              }}
              label="Sair"
              isActive={false}
              borderTopStartRadius={0}
              borderTopEndRadius={0}
              className="custom-menu-item logout"
            />
          </MenuList>
        </>
      )}

    </Menu>
  )
}
