type PageSettings = {
  [key: string]: {
    title: string;
    subtitle?: string;
  }
}

export const settings: PageSettings = {
  home: {
    title: 'Boas-vindas ao Destaque no Catálogo Memed',
    subtitle: `Para destacar os produtos da sua empresa no nosso catálogo,
    basta preencher os dados solicitados nesta página.`
  },
  cart: {
    title: 'Cesta de produtos',
    subtitle: 'Antes de finalizar sua reserva confira os detalhes dos períodos e posições dos produtos selecionados'
  },
  details: {
    title: 'Detalhes da reserva',
    subtitle: ''
  },
  checkout: {
    title: 'Detalhes da reserva'
  },
  profile: {
    title: 'Configurações',
    subtitle: 'Para editar seus dados pessoais e os dados da indústria para a qual você deseja realizar reservas de destaque, insira as informações nos campos abaixo:'
  }
}
