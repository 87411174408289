import {
  Button, Flex, Heading, IconFeatured, Text
} from '@memed/epiderme'
import { INITIAL_STATE, useAdWordStore } from '@store/useAdWordStore'
import { useMoleculeStore } from '@store/useMoleculesStore'
import { useNavigate } from 'react-router-dom'

interface IEmptyCart {
  title: string;
  message: string[];
}

export const EmptyCart = ({ title, message }: IEmptyCart) => {
  const navigate = useNavigate()
  const clearMolecules = useMoleculeStore((state) => state.clear)
  const { setType, setPresentation } = useAdWordStore((state) => state)

  const handleClickBack = () => {
    clearMolecules()
    setPresentation(INITIAL_STATE.presentation)
    setType(INITIAL_STATE.type)
    navigate('/')
  }

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      border="1px"
      borderRadius="8px"
      borderColor="neutral.300"
      padding="1.5rem"
    >
      <IconFeatured size={20} icon="circle-shopping-basket" colorScheme="secondary" hasBorder />
      <Heading
        fontWeight="normal"
        size="md"
        as="h2"
        color="neutral.600"
        m={4}
      >
        {title}
      </Heading>
      <Text color="neutral.500" mb={1} fontSize="14px">{message[0]}</Text>
      <Text color="neutral.500" mb={6} fontSize="14px">{message[1]}</Text>
      <Button
        size="sm"
        width="11.625rem"
        onClick={handleClickBack}
      >
        Voltar para o início
      </Button>
    </Flex>
  )
}
