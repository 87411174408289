import { IIngredientType } from '@interfaces/IngredientType'
import { RadioButton, RadioGroup, Text } from '@memed/epiderme'
import { INITIAL_STATE, useAdWordStore } from '@store/useAdWordStore'
import { useMoleculeStore } from '@store/useMoleculesStore'

export const MoleculeType = () => {
  const setType = useAdWordStore((state) => state.setType)
  const ingredientType = useAdWordStore((state) => state.type)
  const setPresentation = useAdWordStore((state) => state.setPresentation)
  const setSearchTermPresentation = useAdWordStore((state) => state.setSearchTermPresentation)
  const setSelectedHarvest = useAdWordStore((state) => state.setSelectedHarvest)
  const clearMolecules = useMoleculeStore((state) => state.clear)

  const handleChange = (e: IIngredientType) => {
    setType(e)
    clearMolecules()
    setSearchTermPresentation(INITIAL_STATE.searchTermPresentation)
    setPresentation(INITIAL_STATE.presentation)
    setSelectedHarvest(INITIAL_STATE.selectedHarvest)
  }
  return (
    <RadioGroup display="flex" gap={4} mb={2} value={ingredientType} onChange={(e: IIngredientType) => handleChange(e)}>
      <RadioButton
        colorScheme="primary"
        size="lg"
        value="SIMPLE_INGREDIENT"
      >
        <Text fontSize="md">Molécula simples</Text>
      </RadioButton>
      <RadioButton
        colorScheme="primary"
        size="lg"
        value="COMPOUND_INGREDIENT"
      >
        <Text fontSize="md">Molécula composta</Text>
      </RadioButton>
    </RadioGroup>
  )
}
