type FormatCurrency = {
  value: number
  locale?: string
  currency?: string
}
export const formatCurrency = (
  { value, locale = 'pt-br', currency = 'BRL' }: FormatCurrency
) => Intl.NumberFormat(locale, {
  currency,
  style: 'currency'
}).format(value)
