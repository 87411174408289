import { IIndustry } from '@interfaces/Industry'
import { Tag } from '@memed/epiderme'

interface IIndustryItem {
  industry: IIndustry
  isDisabled: boolean
  handleClick: (industry: IIndustry) => void
}

export const IndustryTag = ({ industry, isDisabled, handleClick }: IIndustryItem) => (
  <Tag
    color="secondary"
    iconRight="close"
    size="md"
    variant="light"
    {...(isDisabled && { opacity: '50%' })}
    {...(!isDisabled && { onClick: () => handleClick(industry) })}
    cursor={isDisabled ? 'not-allowed' : 'pointer'}
    userSelect="none"
    key={industry.id}
  >
    {industry.name}
  </Tag>
)
