import { IMolecule } from '@interfaces/Molecule'
import { Box, Text } from '@memed/epiderme'

interface IMoleculeItem {
  molecule: IMolecule
  handleClick: (molecule: IMolecule) => void
}

export const MoleculeItem = ({ molecule, handleClick }: IMoleculeItem) => (
  <Box
    as="button"
    paddingY={2}
    paddingX={3}
    cursor="pointer"
    display="flex"
    gap={3}
    marginTop={2}
    key={molecule.id}
    width="100%"
    _hover={{
      background: 'primary.50'
    }}
    onClick={() => handleClick(molecule)}
  >
    <Text lineHeight="base" color="gray.700" fontSize="sm">{molecule.nome}</Text>
  </Box>
)
