import { IHarvest, ISeasonSelected } from '@interfaces/Harvest'
import { IIngredientType } from '@interfaces/IngredientType'
import { IPresentation } from '@interfaces/Presentation'
import { create } from 'zustand'

export interface IStore {
  type: IIngredientType;
  setType: (type: IIngredientType) => void;
  presentation: IPresentation;
  setPresentation: (presentation: IPresentation) => void;
  clearPresentation: () => void;

  searchTermPresentation: string;
  setSearchTermPresentation: (searchTerm: string) => void

  harvests: IHarvest[];
  setHarvests: (harvests: IHarvest[]) => void;

  selectedHarvest: ISeasonSelected[];
  setSelectedHarvest: (seasons: ISeasonSelected[]) => void;

  shouldShowAlertMessage: boolean;
  setShouldShowAlertMessage: (boolean: boolean) => void;
}

export const INITIAL_STATE = {
  type: 'SIMPLE_INGREDIENT' as const,
  presentation: {} as IPresentation,
  searchTermPresentation: '',
  harvests: [] as IHarvest[],
  selectedHarvest: [] as ISeasonSelected[],
  shouldShowAlertMessage: false
}

export const useAdWordStore = create<IStore>()((set) => ({
  type: INITIAL_STATE.type,
  setType: (type) => set(() => ({ type })),

  presentation: INITIAL_STATE.presentation,
  setPresentation: (presentation) => set(() => ({ presentation })),
  clearPresentation: () => set(() => ({ presentation: {} as IPresentation })),

  searchTermPresentation: INITIAL_STATE.searchTermPresentation,
  setSearchTermPresentation: (searchTermPresentation) => set(() => ({ searchTermPresentation })),

  harvests: INITIAL_STATE.harvests,
  setHarvests: (harvests) => set(() => ({ harvests })),

  selectedHarvest: INITIAL_STATE.selectedHarvest,
  setSelectedHarvest: (season) => set(() => ({ selectedHarvest: season })),

  shouldShowAlertMessage: INITIAL_STATE.shouldShowAlertMessage,
  setShouldShowAlertMessage: (boolean) => set(() => ({ shouldShowAlertMessage: boolean }))
}))
