export class UnauthorizedError extends Error {
  constructor(
    public message: string,
    public code: number,
    public data?: any
  ) {
    super(message)
    this.name = 'UnauthorizedError'
  }
}
