import { Center, Spinner } from '@chakra-ui/react'
import { SearchWrapper } from '@components/SearchWrapper'
import { useDebounce } from '@hooks/useDebounce'
import { IPresentation } from '@interfaces/Presentation'
import {
  Box, Button, Icon, InputText, Modal, ModalBody, ModalFooter, ModalHeader, Text
} from '@memed/epiderme'
import PresentationRepository from '@services/api/PresentationRepository'
import { INITIAL_STATE, useAdWordStore } from '@store/useAdWordStore'
import { useCartStore } from '@store/useCartStore'
import { useMoleculeStore } from '@store/useMoleculesStore'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PresentationItem } from './PresentationItem'

export const PresentationSearch = () => {
  const [presentations, setPresentations] = useState<IPresentation[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isDuplicated, setIsDuplicated] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)
  const { searchTermPresentation, setSearchTermPresentation } = useAdWordStore((state) => state)
  const ingredientType = useAdWordStore((state) => state.type)
  const molecules = useMoleculeStore((state) => state.molecules)
  const cart = useCartStore((state) => state.cart)
  const storePresentation = useAdWordStore(({ presentation }) => presentation)
  const setStorePresentation = useAdWordStore(({ setPresentation }) => setPresentation)
  const setSelectedHarvest = useAdWordStore((state) => state.setSelectedHarvest)
  const debouncedTerm: string = useDebounce<string>(searchTermPresentation, 700)

  const hasMolecules = molecules && molecules.length > 0

  const navigate = useNavigate()

  const getPresentation = async (term?: string) => {
    try {
      setIsLoading(true)
      if (hasMolecules) {
        const response = await PresentationRepository.get({
          name: term || '',
          type: ingredientType,
          compositions: molecules
        })
        setPresentations(response)
        setShowDropdown(true)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setSelectedHarvest(INITIAL_STATE.selectedHarvest)
    if (debouncedTerm) {
      getPresentation(debouncedTerm)
      return
    }
    setPresentations([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTerm])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setStorePresentation(INITIAL_STATE.presentation)
    }
    setSearchTermPresentation(e.target.value)
  }

  const handleClickPresentation = (presentation: IPresentation) => {
    setShowDropdown(false)
    setSearchTermPresentation('')
    setStorePresentation(presentation)
    const isAlreadyInCart = cart.find((item) => item.presentation.id === presentation.id)
    if (isAlreadyInCart) {
      setIsDuplicated(true)
    }
  }

  const renderDropdownOrClear = () => {
    if (!storePresentation.id) {
      return (
        <Box
          cursor="pointer"
          onClick={() => setShowDropdown(true)}
        >
          <Icon icon="chevron-down" color="neutral.600" />
        </Box>
      )
    }
    return (
      <Box
        cursor="pointer"
        onClick={() => {
          setSearchTermPresentation('')
          setStorePresentation(INITIAL_STATE.presentation)
        }}
      >
        <Icon icon="close" color="neutral.600" />
      </Box>
    )
  }

  const handleClickChangePresentation = () => {
    setIsDuplicated(false)
    setStorePresentation(INITIAL_STATE.presentation)
  }

  const handleClickEditCartItem = () => {
    setIsDuplicated(false)
    const timestamp = cart.find((item) => item.name === storePresentation.name)?.timestamp
    if (timestamp) navigate(`/edit/${timestamp}`)
  }

  const handleCloseModal = () => {
    setIsDuplicated(false)
  }

  const hasPresentation = presentations && presentations.length > 0

  const inputValue = storePresentation.name && storePresentation.description
    ? `${storePresentation.name}, ${storePresentation.description}`
    : searchTermPresentation

  const handleOnBlur = () => {
    const presentationTerm = `${storePresentation.name}, ${storePresentation.description}`
    if (searchTermPresentation && searchTermPresentation !== presentationTerm) {
      setSearchTermPresentation('')
      setStorePresentation(INITIAL_STATE.presentation)
    }
  }

  return (
    <Box mt={6} position="relative">
      <InputText
        iconLeft="search-loupe"
        placeholder="Digite ou selecione uma composição"
        label="Apresentação"
        onChange={handleChange}
        iconRight={hasMolecules ? renderDropdownOrClear() : null}
        value={inputValue}
        isDisabled={!hasMolecules}
        onBlur={handleOnBlur}
      />
      <SearchWrapper showDropdown={showDropdown} onClose={setShowDropdown}>
        {hasPresentation && !isLoading && presentations?.map((presentation) => (
          <PresentationItem key={presentation.id} handleClick={handleClickPresentation} presentation={presentation}>
            {`${presentation.name}, ${presentation.description}`}
          </PresentationItem>
        ))}
        {!hasPresentation && !isLoading && (
          <Center mt={2} py={2}>
            <Text fontSize="sm">Não há resultados para exibir</Text>
          </Center>
        )}
        {isLoading && <Center marginTop={2}><Spinner /></Center>}
      </SearchWrapper>
      <Modal isOpen={isDuplicated} onClose={handleCloseModal} size="md">
        <ModalHeader>Item duplicado</ModalHeader>
        <ModalBody>
          {`${storePresentation.name} já está na cesta, é recomendado editar o ciclo
          , ou alterar a apresentação`}
        </ModalBody>
        <ModalFooter gap={4}>
          <Button onClick={handleClickChangePresentation} size="sm" variant="ghost">
            Alterar apresentação
          </Button>
          <Button onClick={handleClickEditCartItem} size="sm" px={4}>
            Editar o item da cesta
          </Button>
        </ModalFooter>
      </Modal>
    </Box>
  )
}
