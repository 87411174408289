import { Center, Spinner } from '@chakra-ui/react'
import { SearchWrapper } from '@components/SearchWrapper'
import { useDebounce } from '@hooks/useDebounce'
import { IMolecule } from '@interfaces/Molecule'
import {
  Box, InputText, Text, TooltipHelper
} from '@memed/epiderme'
import MoleculesRepository from '@services/api/MoleculesRepository'
import { INITIAL_STATE, useAdWordStore } from '@store/useAdWordStore'
import { useMoleculeStore } from '@store/useMoleculesStore'
import { ChangeEvent, useEffect, useState } from 'react'
import { MoleculeItem } from './MoleculeItem'
import { MoleculeTag } from './MoleculeTag'

export const MoleculesSearch = () => {
  const [molecules, setMolecules] = useState<IMolecule[]>()
  const [isLoading, setIsLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [showDropdown, setShowDropdown] = useState(false)
  const debouncedTerm: string = useDebounce<string>(searchTerm, 700)

  const add = useMoleculeStore((state) => state.add)
  const remove = useMoleculeStore((state) => state.remove)
  const selectedMolecules = useMoleculeStore((state) => state.molecules)
  const ingredientType = useAdWordStore((state) => state.type)
  const setPresentation = useAdWordStore((state) => state.setPresentation)
  const setSearchTermPresentation = useAdWordStore((state) => state.setSearchTermPresentation)
  const setSelectedHarvest = useAdWordStore((state) => state.setSelectedHarvest)

  const getMoleculesByTerm = async (term: string) => {
    setIsLoading(true)
    const response = await MoleculesRepository.getByName(term)
    setMolecules(response)
    setIsLoading(false)
  }

  useEffect(() => {
    if (debouncedTerm) {
      getMoleculesByTerm(debouncedTerm)
      setShowDropdown(true)
      return
    }
    setMolecules([])
  }, [debouncedTerm])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleClickMolecule = (molecule: IMolecule) => {
    const alreadyOnList = selectedMolecules.find((mol) => mol.id === molecule.id)
    setShowDropdown(false)
    setSearchTerm('')
    if (alreadyOnList) return

    add(molecule)
  }

  const handleClickTag = (molecule: IMolecule) => {
    setSelectedHarvest(INITIAL_STATE.selectedHarvest)
    setSearchTermPresentation(INITIAL_STATE.searchTermPresentation)
    setPresentation(INITIAL_STATE.presentation)
    remove(molecule)
  }

  const hasMoleculeResults = molecules && molecules.length > 0
  const isSimpleIngredient = ingredientType === 'SIMPLE_INGREDIENT' && selectedMolecules.length === 1

  return (
    <Box position="relative">
      <InputText
        iconLeft="search-loupe"
        placeholder="Busque por princípio ativo ou composição"
        onChange={handleChange}
        value={searchTerm}
        isDisabled={isSimpleIngredient}
      />
      <SearchWrapper showDropdown={showDropdown} onClose={setShowDropdown} noLabel>
        {hasMoleculeResults && molecules.map((molecule) => (
          <MoleculeItem molecule={molecule} handleClick={handleClickMolecule} key={molecule.id} />
        ))}
        {isLoading && <Center marginTop={2}><Spinner /></Center>}
        {!hasMoleculeResults && !isLoading && (
          <Center mt={2} py={2}>
            <Text fontSize="sm">Não há resultados para exibir</Text>
          </Center>
        )}
      </SearchWrapper>
      <Box mt={2} minH="1.75rem" display="flex" gap="3" flexWrap="wrap">
        {selectedMolecules.length >= 1 && (
          <>
            {selectedMolecules.map((molecule: IMolecule) => (
              <MoleculeTag molecule={molecule} handleClick={handleClickTag} key={molecule.id} />
            ))}
            {isSimpleIngredient && (
            <Center>
              <TooltipHelper
                labelContent="Só é possível selecionar uma molécula simples por vez,
             se deseja adicionar outras moléculas, é necessário selecionar a opção molécula composta"
              />
            </Center>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}
