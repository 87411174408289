/* eslint-disable no-param-reassign */
import { IUserProfile } from '@interfaces/User'
import { create } from 'zustand'
import produce from 'immer'

export interface IStore {
  userProfile: IUserProfile;
  actions: {
    setUserProfile: (userProfile: IUserProfile) => void
  }
}

const INITIAL_STATE = {
  userProfile: {
    id: '',
    pipdriveId: '',
    email: '',
    cnpj: '',
    corporateName: '',
    customer: '',
    telephone: '',
    industries: []
  } as IUserProfile
}

export const useUserProfileStore = create<IStore>((set) => {
  const setState = (fn: (state: IStore) => void) => set(produce(fn))

  return {
    userProfile: INITIAL_STATE.userProfile,
    actions: {
      setUserProfile(userProfile: IUserProfile) {
        setState((state) => {
          state.userProfile = { ...userProfile }
        })
      }
    }
  }
})
