import axios from 'axios'
import { AUTH_URL, KEYCLOAK_REALM } from '@configs/env'

export const keycloakApi = axios.create({
  baseURL: `${AUTH_URL}/realms/${KEYCLOAK_REALM}`,
  responseType: 'json'
})

keycloakApi.interceptors.request.use((config) => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Bearer ${localStorage.getItem('@memadwords:accessToken') ?? ''}`
  }
}))

keycloakApi.interceptors.response.use(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  (response) => (response),
  (error) => Promise.reject(error)
)
