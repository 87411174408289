import {
  Modal as ModalEpiderme, ModalHeader, ModalBody, ModalFooter, Button
} from '@memed/epiderme'

interface IModal {
  onClick: () => void
  onClose: () => void
  isOpen: boolean
  isLoading: boolean
  header: string
  content: string
  buttonNameOk: string
  buttonNameCancel: string
}
export const Modal = ({
  onClick, onClose, isOpen, isLoading, header, content, buttonNameOk, buttonNameCancel
}: IModal) => (
  <ModalEpiderme isOpen={isOpen} onClose={() => onClose()} size="md">
    <ModalHeader>{header}</ModalHeader>
    <ModalBody>
      {content}
    </ModalBody>
    <ModalFooter>
      <Button size="sm" padding="1rem" variant="ghost" onClick={onClose}>
        {buttonNameCancel}
      </Button>
      <Button
        size="sm"
        padding="1rem"
        colorScheme="primary"
        ml={3}
        isLoading={isLoading}
        onClick={onClick}
      >
        {buttonNameOk}
      </Button>
    </ModalFooter>
  </ModalEpiderme>
)
