import { IMolecule } from '@interfaces/Molecule'
import { Tag } from '@memed/epiderme'

interface IMoleculeItem {
  molecule: IMolecule
  handleClick: (molecule: IMolecule) => void
}

export const MoleculeTag = ({ molecule, handleClick }: IMoleculeItem) => (
  <Tag
    color="secondary"
    iconRight="close"
    size="md"
    variant="light"
    onClick={() => handleClick(molecule)}
    cursor="pointer"
    userSelect="none"
    key={molecule.id}
  >
    {molecule.nome}
  </Tag>
)
