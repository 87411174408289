/* eslint-disable no-param-reassign */
import { IIndustry } from '@interfaces/Industry'
import { IUserFormData } from '@interfaces/User'
import { create } from 'zustand'
import produce from 'immer'

export interface IStore {
  userFormData: IUserFormData;
  actions: {
    addIndustry: (industry: IIndustry) => void,
    removeIndustry: (industry: IIndustry) => void,
    setUserFormData: (userFormData: IUserFormData) => void
    setShowValidation: (value: boolean) => void
  }
}

const INITIAL_STATE = {
  userFormData: {
    id: '',
    pipdriveId: '',
    email: '',
    cnpj: '',
    corporateName: '',
    customer: '',
    telephone: '',
    showValidation: false,
    industries: []
  } as IUserFormData
}

export const useUserFormDataStore = create<IStore>((set) => {
  const setState = (fn: (state: IStore) => void) => set(produce(fn))

  return {
    userFormData: INITIAL_STATE.userFormData,
    actions: {
      addIndustry(industry: IIndustry) {
        setState(({ userFormData }) => {
          userFormData.industries = [
            ...(userFormData.industries ?? []), industry
          ]
        })
      },
      removeIndustry(industry: IIndustry) {
        setState(({ userFormData }) => {
          userFormData.industries = userFormData.industries
            ?.filter(({ id }) => id !== industry.id)
        })
      },
      setShowValidation(value) {
        setState(({ userFormData }) => {
          userFormData.showValidation = value
        })
      },
      setUserFormData(userFormData: IUserFormData) {
        setState((state) => {
          state.userFormData = { ...userFormData }
        })
      }
    }
  }
})
