import { AxiosError } from 'axios'
import { BadRequestError } from '@services/api/errors/BadRequestError'
import { InternalServerError } from '@services/api/errors/InternalServerError'
import { UnauthorizedError } from '@services/api/errors/UnauthorizedError'
import { UnavailablePositionError } from '@services/api/errors/UnavailablePositionError'
import { DefaultError } from '@services/api/errors/DefaultError'

import { IConflictResponse } from '@interfaces/IConflictResponse'

export const defaultMessage = 'Ops, aconteceu algo errado, por favor, entre em contato com os administradores'

export function AdDrugsMiddlewareError(error: AxiosError) {
  if (error.response) {
    switch (error.response.status) {
      case 409: {
        const { data } = <IConflictResponse> error.response.data
        console.error(error.message)
        return Promise.reject(new UnavailablePositionError(
          'Há posições indisponíveis nos ciclos, por favor revise o carrinho e selecione outras posições',
          error.response.status,
          data
        ))
      }
      case 400: {
        console.error(error.message)
        return Promise.reject(new BadRequestError(
          defaultMessage,
          error.response.status
        ))
      }
      case 401: {
        console.error(error.message)
        return Promise.reject(new UnauthorizedError(
          defaultMessage,
          error.response.status
        ))
      }
      case 500: {
        console.error(error.message)
        return Promise.reject(new InternalServerError(
          'Ops, aconteceu algo errado, por favor, tente novamente mais tarde',
          error.response.status
        ))
      }
      default: {
        console.error(error.message)
        return Promise.reject(new DefaultError(
          defaultMessage,
          error.response.status
        ))
      }
    }
  } else {
    console.error(error.message)
    return Promise.reject(new DefaultError(
      defaultMessage
    ))
  }
}
