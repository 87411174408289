import axios from 'axios'
import { API_URL } from '@configs/env'
import { AdDrugsMiddlewareError } from './middlewares/errors/AdDrugsMiddlewareError'

export const api = axios.create({
  baseURL: API_URL,
  responseType: 'json',
  timeout: 15 * 1000
})

api.interceptors.request.use((config) => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Bearer ${localStorage.getItem('@memadwords:accessToken') ?? ''}`
  }
}))

api.interceptors.response.use(
  (response) => (response),
  AdDrugsMiddlewareError
)
