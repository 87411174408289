import { IPresentation } from '@interfaces/Presentation'
import { Box, Text } from '@memed/epiderme'
import { ReactNode } from 'react'

interface IPresentationItem {
  children: ReactNode
  handleClick: (presentation: IPresentation) => void
  presentation: IPresentation
}

export const PresentationItem = ({ children, handleClick, presentation }: IPresentationItem) => (
  <Box
    as="button"
    paddingY={2}
    paddingX={3}
    cursor="pointer"
    display="flex"
    gap={3}
    marginTop={2}
    width="100%"
    _hover={{
      background: 'primary.50'
    }}
    onClick={() => handleClick(presentation)}
  >
    <Text lineHeight="base" color="gray.700" fontSize="sm">
      {children}
    </Text>
  </Box>
)
