import { Tab } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import theme from '@memed/epiderme'

export const ChakraTab = styled(Tab)`
  color: ${theme.colors.neutral['600']};
  position: relative;
  &:hover {
    color: ${theme.colors.neutral['800']};
    &:after {
      content: "";
      width: 100%;
      height: 0.25rem;
      background-color: ${theme.colors.neutral['200']};
      opacity: 1;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
  };
  &:after {
    content: "";
    display: flex;
    width: 0;
    height: 0.25rem;
    background-color: ${theme.colors.primary['600']};
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    transition: all 0.2s ease 0s;
  };
  ${(props) => props['data-is-active'] && css`
    &[aria-selected=true] {
      color: ${theme.colors.neutral['800']};
      &:after {
        content: "";
        display: flex;
        width: 100%;
        height: 0.25rem;
        background-color: ${theme.colors.primary['600']};
        opacity: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        transition: all 0.2s ease 0s;
      };
    }
    `}
`
