/* eslint-disable @typescript-eslint/no-unsafe-call */
import { ClientAccordion } from '@components/Accordion/ClientAccordion'
import { ExecutiveAccordion } from '@components/Accordion/ExecutiveAccordion'
import { CartItem } from '@components/CartItem'
import { Cart as CartComponent } from '@components/Cart'
import { EmptyCart } from '@components/EmptyCart/EmptyCart'
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useToast
} from '@memed/epiderme'
import AdsRepository from '@services/api/AdsRepository'
import { INITIAL_STATE, useAdWordStore } from '@store/useAdWordStore'
import { useCartStore } from '@store/useCartStore'
import { useHarvestsErrorsStore } from '@store/useHarvestsErrorsStore'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useMoleculeStore } from '@store/useMoleculesStore'
import { UnavailablePositionError } from '@services/api/errors/UnavailablePositionError'
import { defaultMessage } from '@services/api/middlewares/errors/AdDrugsMiddlewareError'
import { scrollTo } from '@utils/scrollTo'

export const Cart = () => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const cart = useCartStore((state) => state.cart)
  const { setHarvestErrors, actions: { clear: clearHarvestErrors } } = useHarvestsErrorsStore((state) => state)
  const clearMolecules = useMoleculeStore((state) => state.clear)
  const clearPresentation = useAdWordStore((state) => state.clearPresentation)
  const setType = useAdWordStore((state) => state.setType)
  const clearCart = useCartStore((state) => state.clear)
  const toast = useToast()

  const getDate = () => {
    const options = {
      year: '2-digit' as const,
      month: 'numeric' as const,
      day: 'numeric' as const
    }
    const date = new Date()
    return new Intl.DateTimeFormat('pt-BR', options).format(date)
  }

  const clearState = () => {
    clearHarvestErrors()
    clearPresentation()
    clearMolecules()
    clearCart()
    setType(INITIAL_STATE.type)
  }

  const handleSuccess = async () => {
    try {
      setIsLoading(true)
      await AdsRepository.post(cart)
      clearState()
      navigate('/checkout/success')
      scrollTo(60)
    } catch (error: any) {
      if (error instanceof UnavailablePositionError) {
        setHarvestErrors(error.data)
        toast({
          description: error.message,
          position: 'bottom-left',
          isClosable: true,
          duration: 6000,
          status: 'warning'
        })
        navigate('/checkout/error')
        scrollTo(60)
        return
      }
      toast({
        description: error?.message ?? defaultMessage,
        position: 'bottom-left',
        isClosable: true,
        duration: 6000,
        status: 'error'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const newMolecule = () => {
    clearPresentation()
    clearMolecules()
    setType(INITIAL_STATE.type)
    navigate('/')
    scrollTo(60)
  }

  return (
    <Box>
      {/* <BackButton /> */}
      <Flex justifyContent="start" mt={4} mb={6}>
        <Heading as="h2" type="subtitle" fontWeight="medium" width="18.125rem">Resumo dos itens</Heading>
        <Text fontWeight="medium" color="neutral.600" fontSize="16px">{`Data: ${getDate()}`}</Text>
      </Flex>

      <ClientAccordion />
      <ExecutiveAccordion />

      {!cart.length ? (
        <EmptyCart
          title="Sua cesta está vazia"
          message={[
            'Adicione reservas de ciclos e posições na sua cesta.',
            'Você poderá visualizar o resumo dos seus itens e editar seu pedido.'
          ]}
        />
      ) : null}

      {cart.length ? (
        <CartComponent>
          {cart.map((item) => (<CartItem item={item} key={item.timestamp} />))}
        </CartComponent>
      ) : null}

      {cart.length ? (
        <Flex justifyContent="end" gap={4} mt={4}>
          <Button
            size="sm"
            px={4}
            colorScheme="primary"
            variant="outline"
            onClick={newMolecule}
          >
            Selecionar nova molécula
          </Button>
          <Button
            size="sm"
            px={4}
            isLoading={isLoading}
            colorScheme="primary"
            variant="solid"
            onClick={() => { handleSuccess() }}
          >
            Finalizar reserva
          </Button>
        </Flex>

      ) : null}

    </Box>
  )
}
