/* eslint-disable no-useless-catch */
import { IKeycloakUserResponse, IUserProfile } from '@interfaces/User'
import { keycloakApi } from '@services/api'
import { AxiosResponse } from 'axios'

type IAxiosKeycloakUserResponse = AxiosResponse<IKeycloakUserResponse>

class KeycloakUserRepository {
  public static async show() {
    const response: IAxiosKeycloakUserResponse = await keycloakApi.get('/account')
    return this.keycloakUserMapper(response)
  }

  public static keycloakUserMapper(response: IAxiosKeycloakUserResponse) {
    const { id, username, attributes } = response.data
    return {
      id,
      email: username,
      attributes: {
        cnpj: attributes.cnpj.toString(),
        corporateName: attributes['corporate-name'].toString(),
        customer: attributes.customer.toString(),
        telephone: attributes.telephone.toString(),
        pipdriveId: attributes['pipdrive-id'].toString(),
        industries: attributes.industries.join('##').split('##')
          .map((industry) => JSON.parse(industry))
      }
    }
  }

  public static async update({
    pipdriveId, email, industries, cnpj, corporateName, customer, telephone
  }: IUserProfile) {
    const body = {
      email,
      attributes: {
        'pipdrive-id': [
          pipdriveId
        ],
        'industries': industries.map((industry) => JSON.stringify(industry)),
        'cnpj': [
          cnpj
        ],
        'corporate-name': [
          corporateName
        ],
        'customer': [
          customer
        ],
        'telephone': [
          telephone
        ]
      }
    }
    return keycloakApi.post('/account', { ...body })
  }
}

export default KeycloakUserRepository
