import { Text } from '@memed/epiderme'
import { useUserProfileStore } from '@store/useUserProfileStore'

export const Username = () => {
  const userProfile = useUserProfileStore((state) => state.userProfile)

  return (
    <Text
      fontWeight="medium"
      as="label"
      letterSpacing="tight"
    >
      {userProfile.email}
    </Text>
  )
}
