import {
  HStack
} from '@chakra-ui/react'
import theme, { Box, ButtonIcon, Tag } from '@memed/epiderme'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCartStore } from '@store/useCartStore'
import { Logo } from './Logo'
import { MenuLink } from './MenuLink'
import { UserMenu } from './UserMenu'
import { Header } from './styles'

export function Navbar() {
  const location = useLocation()
  const navigate = useNavigate()
  const cart = useCartStore((state) => state.cart)

  const isCartPage = location.pathname.includes('cart')

  return (
    <Header>
      <Box
        display="flex"
        margin="0 auto"
        maxW="calc(100% - 9.75rem)"
        h={theme.sizes['14']}
        alignItems="center"
        justifyContent="space-between"
      >
        <HStack gap={theme.sizes['8']} alignItems="center">
          <Logo />
          <MenuLink />
        </HStack>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={theme.sizes['4']}
        >
          <UserMenu />
          {!isCartPage && (
            <Box position="relative">
              <ButtonIcon
                icon="shopping-basket"
                variant="ghost"
                colorScheme="neutral"
                size="sm"
                onClick={() => navigate('/cart')}
                isRound
              />
              {cart.length > 0 && (
              <Tag
                number={cart.length}
                size="sm"
                color="danger"
                position="absolute"
                top={2}
                right={0}
              />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Header>
  )
}
