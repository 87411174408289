/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, useDisclosure } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import {
  Button,
  Flex,
  Heading,
  IconFeatured,
  InputText,
  InputCnpj,
  InputEmail,
  Form,
  IFormData,
  Icon,
  IconList,
  Tooltip,
  InputPhone
} from '@memed/epiderme'
import { useUserProfileStore } from '@store/useUserProfileStore'
import { useUserFormDataStore } from '@store/useUserFormDataStore'
import KeycloakUserRepository from '@services/api/keycloakUserRepository'
import { IndustriesSearch } from '@components/IndustriesSearch'
import { Modal } from '@components/Modal'
import { useMoleculeStore } from '@store/useMoleculesStore'
import { INITIAL_STATE, useAdWordStore } from '@store/useAdWordStore'
import { useCartStore } from '@store/useCartStore'
import { useHarvestsErrorsStore } from '@store/useHarvestsErrorsStore'
import { IEdit, IFormControls } from './interfaces'

const PersonalData = ({ handleClickEdit, editEnabled }: IEdit) => {
  const { userFormData } = useUserFormDataStore()
  const { setUserFormData, setShowValidation } = useUserFormDataStore((state) => state.actions)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowValidation(false)
    const { name: key, value } = e.target
    setUserFormData(({ ...userFormData, [key]: value }))
  }

  return (
    <>
      <Flex justifyContent="space-between" mb={4}>
        <Flex gap={4} alignItems="center">
          <IconFeatured icon="user" hasBorder />
          <Heading as="h2" type="subtitle" fontWeight="medium">Dados pessoais</Heading>
        </Flex>
        <Flex alignItems="center">
          <Button
            variant="ghost"
            rightIcon="document-note"
            size="sm"
            onClick={handleClickEdit}
          >
            Editar
          </Button>
        </Flex>
      </Flex>
      <Divider borderColor="neutral.400" mb={8} />
      <Flex gap={6} mb={6}>
        <InputText
          label="ID do Pipedrive"
          name="pipdriveId"
          placeholder="Id do Pipedrive"
          showValidation={userFormData.showValidation}
          value={userFormData.pipdriveId}
          isDisabled={!editEnabled}
          isRequired
          onChange={handleInputChange}
        />
        <InputPhone
          label="Telefone"
          name="telephone"
          placeholder="(11) 99999-9999"
          showValidation={userFormData.showValidation}
          value={userFormData.telephone}
          isDisabled={!editEnabled}
          isRequired
          onChange={handleInputChange}
        />
      </Flex>
      <Flex gap={6} mb={12}>
        <InputEmail
          label="E-mail Memed"
          name="email"
          maxWidth="22.6875rem"
          placeholder="Email Memed"
          showValidation={userFormData.showValidation}
          value={userFormData.email}
          isDisabled
          isRequired
          onChange={handleInputChange}
        />
      </Flex>
    </>
  )
}

const IndustryData = ({
  handleClickEdit,
  editEnabled
}: IEdit) => {
  const { userFormData } = useUserFormDataStore((state) => state)
  const { setUserFormData, setShowValidation } = useUserFormDataStore((state) => state.actions)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowValidation(false)
    const { name: key, value } = e.target
    setUserFormData(({ ...userFormData, [key]: value }))
  }

  return (
    <>
      <Flex justifyContent="space-between" mb={4}>
        <Flex gap={4} alignItems="center">
          <IconFeatured icon="industries" hasBorder />
          <Heading as="h2" type="subtitle" fontWeight="medium">Dados da indústria</Heading>
        </Flex>
        <Flex alignItems="center">
          <Button
            variant="ghost"
            rightIcon="document-note"
            size="sm"
            onClick={handleClickEdit}
          >
            Editar
          </Button>
        </Flex>
      </Flex>
      <Divider borderColor="neutral.400" mb={8} />
      <Flex gap={6} mb={6}>
        <InputText
          label="Nome do cliente contratante"
          name="customer"
          maxWidth="22.6875rem"
          placeholder="Nome e Sobrenome"
          showValidation={userFormData.showValidation}
          value={userFormData.customer}
          isDisabled={!editEnabled}
          isRequired
          onChange={handleInputChange}
        />
      </Flex>
      <Flex gap={6} mb={6}>
        <InputCnpj
          label="CNPJ do contrato"
          name="cnpj"
          placeholder="CNPJ do contrato"
          showValidation={userFormData.showValidation}
          iconRight={(
            <Tooltip
              labelContent="Este deve ser o CNPJ que irá para o contrato e nota fiscal da reserva"
              placement="bottom-end"
              isDisabled={!editEnabled}
            >
              <Icon icon={IconList.INFO} color={!editEnabled ? 'neutral.400' : 'neutral.900'} />
            </Tooltip>
          )}
          value={userFormData.cnpj}
          isDisabled={!editEnabled}
          isRequired
          onChange={handleInputChange}
        />
        <InputText
          label="Razão Social"
          name="corporateName"
          placeholder="Razão social"
          showValidation={userFormData.showValidation}
          iconRight={(
            <Tooltip
              labelContent="Esta deve ser a Razão Social que irá para o contrato e nota fiscal da reserva"
              placement="bottom-end"
              isDisabled={!editEnabled}
            >
              <Icon icon={IconList.INFO} color={!editEnabled ? 'neutral.400' : 'neutral.900'} />
            </Tooltip>
          )}
          value={userFormData.corporateName}
          isDisabled={!editEnabled}
          isRequired
          onChange={handleInputChange}
        />
      </Flex>
      <IndustriesSearch
        isDisabled={!editEnabled}
      />
    </>
  )
}

export const FormControls = ({
  handleClickCancel,
  isLoading,
  editIndustryEnabled,
  editPersonalEnabled
}: IFormControls) => (
  <Flex justifyContent="end" gap={6}>
    <Button
      size="sm"
      variant="outline"
      onClick={handleClickCancel}
      isDisabled={!editIndustryEnabled && !editPersonalEnabled}
    >
      Cancelar
    </Button>
    <Button
      size="sm"
      type="submit"
      isLoading={isLoading}
      isDisabled={!editIndustryEnabled && !editPersonalEnabled}
    >
      Salvar alterações
    </Button>
  </Flex>
)

export const Profile = () => {
  const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure()
  const { keycloak } = useKeycloak()

  const [editPersonalEnabled, setEditPersonalEnabled] = useState(false)
  const [editIndustryEnabled, setEditIndustryEnabled] = useState(false)
  const [loading, setLoading] = useState(false)

  const { userProfile } = useUserProfileStore((state) => state)
  const { setUserProfile } = useUserProfileStore((state) => state.actions)

  const { userFormData } = useUserFormDataStore((state) => state)
  const { setUserFormData, setShowValidation } = useUserFormDataStore((state) => state.actions)

  const clearMolecules = useMoleculeStore((state) => state.clear)
  const clearCart = useCartStore((state) => state.clear)
  const clearHarvestsErrors = useHarvestsErrorsStore((state) => state.actions.clear)
  const setStorePresentation = useAdWordStore(({ setPresentation }) => setPresentation)
  const setType = useAdWordStore((state) => state.setType)

  const clearCartState = () => {
    clearMolecules()
    clearCart()
    clearHarvestsErrors()
    setStorePresentation(INITIAL_STATE.presentation)
    setType(INITIAL_STATE.type)
  }

  const handleClickEditPersonal = () => setEditPersonalEnabled(true)
  const handleClickEditIndustry = () => setEditIndustryEnabled(true)

  const handleClickCancel = () => {
    setEditIndustryEnabled(false)
    setEditPersonalEnabled(false)
    onCloseModal()
  }

  const updateUserProfile = async () => {
    try {
      setLoading(true)
      await KeycloakUserRepository.update({
        ...userFormData,
        industries: userFormData.industries ?? []
      })
      await keycloak.updateToken(-1)
      setUserProfile({ ...userFormData })
      clearCartState()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleConfirmUpdate = async () => {
    await updateUserProfile()
    setEditIndustryEnabled(false)
    setEditPersonalEnabled(false)
    onCloseModal()
  }

  const handleClickSave = (e: IFormData) => {
    if (!e.isValid) {
      setShowValidation(true)
      return
    }
    onOpenModal()
  }

  useEffect(() => {
    setUserFormData({
      ...userProfile,
      showValidation: false
    })
  }, [userProfile])

  return (
    <>
      <Form onSubmit={handleClickSave}>
        <PersonalData
          handleClickEdit={handleClickEditPersonal}
          editEnabled={editPersonalEnabled}
        />
        <IndustryData
          handleClickEdit={handleClickEditIndustry}
          editEnabled={editIndustryEnabled}
        />
        <FormControls
          handleClickCancel={handleClickCancel}
          isLoading={loading}
          editIndustryEnabled={editIndustryEnabled}
          editPersonalEnabled={editPersonalEnabled}
        />
      </Form>
      <Modal
        header="Remover itens da cesta de reservas?"
        content={`
          Ao editar os dados referentes à indústria, como CNPJ e Razão Social,
          você automaticamente removerá os produtos que você adicionou à sua cesta de reservas.
        `}
        buttonNameCancel="Cancelar"
        buttonNameOk="Continuar"
        isOpen={isOpenModal}
        isLoading={loading}
        onClose={handleClickCancel}
        onClick={() => { handleConfirmUpdate() }}
      />
    </>
  )
}
