import {
  HStack, Tabs, TabList
} from '@chakra-ui/react'
import { Text } from '@memed/epiderme'
import { INITIAL_STATE, useAdWordStore } from '@store/useAdWordStore'
import { useMoleculeStore } from '@store/useMoleculesStore'
import { useNavigate } from 'react-router-dom'
import { Tab } from './Tab'

export function MenuLink() {
  const navigate = useNavigate()
  const clearMolecules = useMoleculeStore((state) => state.clear)
  const setStorePresentation = useAdWordStore(({ setPresentation }) => setPresentation)
  const setType = useAdWordStore((state) => state.setType)

  const handleClickHome = () => {
    clearMolecules()
    setStorePresentation(INITIAL_STATE.presentation)
    setType(INITIAL_STATE.type)
    navigate('/')
  }

  const menus = [
    { name: 'Início', onClick: handleClickHome }
  ]

  return (
    <HStack
      as="nav"
      spacing={4}
      display="flex"
    >
      <Tabs colorScheme="primary" variant="unstyled">
        <TabList h="3.5625rem" gap="40px">
          {menus.map((menu) => (
            <Tab key={menu.name} onClick={menu.onClick}>
              <Text letterSpacing="tight" as="label" fontWeight="normal" lineHeight="base">{menu.name}</Text>
            </Tab>
          ))}
        </TabList>
      </Tabs>
    </HStack>
  )
}
