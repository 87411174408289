import { api } from '@services/api'
import { AxiosResponse } from 'axios'
import { IMolecule } from 'src/interfaces/Molecule'

type IAxiosMoleculesResponse = AxiosResponse<IMolecule[]>

class MoleculesRepository {
  public static async getByName(name: string) {
    const response: IAxiosMoleculesResponse = await api.get(`/molecule?nome=${name}`)
    return response.data
  }
}

export default MoleculesRepository
