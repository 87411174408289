import { IHarvestErrors } from '@interfaces/Harvest'

export class UnavailablePositionError extends Error {
  constructor(
    public message: string,
    public code: number,
    public data: IHarvestErrors[]
  ) {
    super(message)
    this.name = 'UnavailablePositionError'
  }
}
