import {
  Flex, Heading, Icon, IconFeatured, Text, Tooltip
} from '@memed/epiderme'
import { IStep } from './interface'

export const Step = ({
  count, title, icon, tooltipLabel
}: IStep) => (
  <Flex mb={8}>
    <IconFeatured icon={icon} size={14} />
    <Flex flexDir="column" justifyContent="space-around" marginLeft={4} marginRight={2}>
      <Text fontSize="sm">{count}</Text>
      <Heading as="h2" type="subtitle">{title}</Heading>
    </Flex>
    <Flex alignSelf="end" pb="3px">
      <Tooltip
        labelContent={tooltipLabel}
        placement="right"
      >
        <Icon
          color="neutral.900"
          icon="info"
        />
      </Tooltip>
    </Flex>
  </Flex>
)
