import ReactDOM from 'react-dom/client'
import theme from '@memed/epiderme'
import { ChakraProvider } from '@chakra-ui/react'
import { App } from './App'

import '@memed/epiderme/lib/assets/fonts/fontFace.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <ChakraProvider theme={theme}>
    <App />
  </ChakraProvider>
)
