/* eslint-disable no-param-reassign */
import { IHarvestMapper } from '@interfaces/HarvestMapper'
import {
  Flex,
  Heading,
  Text
} from '@memed/epiderme'
import { useCartStore } from '@store/useCartStore'
import { ReactNode } from 'react'

export const Cart = ({ children }: { children: ReactNode }) => {
  const cart = useCartStore((state) => state.cart)

  const getTotalValue = () => {
    const harvestsSelected = cart.map((item) => item.harvests).flat()
    const harvests = cart.map((item) => item.data).flat()

    const harvestMapper = harvests.reduce((map, harvest) => {
      map[harvest.id] = harvest.positions
      return map
    }, {} as IHarvestMapper)

    const sumValues = harvestsSelected.reduce((acc, { id: harvestId, position: positionId }) => {
      const positions = harvestMapper[`${harvestId}`]

      const { value } = positions.find((position) => position.id === positionId) || {}
      if (value) {
        acc += value
      }
      return acc
    }, 0)

    return sumValues
  }

  return (
    <Flex border="1px" borderColor="neutral.300" py={6} px={4} flexDir="column" borderRadius="8px">
      <Flex mb={2} justifyContent="space-between">
        <Flex minW="264px">
          <Text fontWeight="bold">Molécula e Apresentação</Text>
        </Flex>
        <Flex minW="188px">
          <Text fontWeight="bold">Preço</Text>
        </Flex>
      </Flex>

      {children}

      <Flex alignSelf="flex-end" mt={6}>
        <Heading as="h3" type="subtitle" minW="68px">Total</Heading>
        <Text color="neutral.500" alignSelf="center">{`(${cart.length} itens)`}</Text>
        <Text color="neutral.500" alignSelf="center">......................................</Text>
        <Heading as="h3" type="subtitle" pr="2.2rem">
          {getTotalValue().toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
        </Heading>
      </Flex>
    </Flex>
  )
}
