import { Box, Image } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import logoDesktop from '@assets/svg/logo-desktop.svg'
import { INITIAL_STATE, useAdWordStore } from '@store/useAdWordStore'
import { useMoleculeStore } from '@store/useMoleculesStore'

export function Logo() {
  const clearMolecules = useMoleculeStore((state) => state.clear)
  const setType = useAdWordStore((state) => state.setType)

  const navigate = useNavigate()

  const handleClickLogo = () => {
    clearMolecules()
    setType(INITIAL_STATE.type)
    navigate('/')
  }

  return (
    <Box>
      <Image
        src={logoDesktop}
        alt="Logo Memed"
        cursor="pointer"
        onClick={handleClickLogo}
      />
    </Box>
  )
}
