/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@memed/epiderme'
import { MoleculesSearch } from '@components/MoleculesSearch'
import { PresentationSearch } from '@components/PresentationSearch'
import { MoleculeType } from '@components/MoleculeType'
import { Step } from '@components/Step'
import { Divider } from '@chakra-ui/react'
import { FormControls } from '@components/FormControls'
import KeycloakUserRepository from '@services/api/keycloakUserRepository'
import { useEffect } from 'react'
import { useUserProfileStore } from '@store/useUserProfileStore'
import { Seasons } from '@components/Seasons'
import { config } from './data'

export const Home = () => {
  const setUserProfile = useUserProfileStore(({ actions }) => actions.setUserProfile)

  const getUser = async () => {
    try {
      const { id, email, attributes } = await KeycloakUserRepository.show()
      setUserProfile({
        email,
        id,
        ...attributes
      })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getUser()
  }, [])

  return (
    <Box>
      <Step {...config.firstStep} />
      <MoleculeType />
      <MoleculesSearch />
      <PresentationSearch />
      <Divider borderColor="neutral.500" marginY={8} />
      <Step {...config.secondStep} />
      <Seasons />
      <Divider borderColor="neutral.500" marginY={8} />
      <FormControls />
    </Box>
  )
}
