import { useKeycloak } from '@react-keycloak/web'
import {
  Navigate, Outlet, Route, Routes
} from 'react-router-dom'

import { DefaultLayout } from '@layouts/DefaultLayout'
import { Login } from '@pages/Login'

function AuthGuard() {
  const { keycloak } = useKeycloak()

  return keycloak?.authenticated ? <Outlet /> : <Navigate to="/login" replace />
}

type RouterGuardProps = {
  children: React.ReactNode
}

export function RouterGuard({ children }: RouterGuardProps) {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<AuthGuard />}>
        <Route path="/" element={<DefaultLayout />}>
          {children}
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  )
}
