import { MouseEventHandler, PropsWithChildren } from 'react'
import { useLocation } from 'react-router-dom'
import { ChakraTab } from './styles'

type TabProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export function Tab({ onClick, children }: PropsWithChildren<TabProps>) {
  const location = useLocation()

  const isHomePage = location.pathname === '/'

  return (
    <ChakraTab onClick={onClick} data-is-active={isHomePage}>
      {children}
    </ChakraTab>
  )
}
