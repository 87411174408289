import { useKeycloak } from '@react-keycloak/web'
import { BrowserRouter, Route } from 'react-router-dom'
import { RouterGuard } from '@routes/RouterGuard'

import { DefaultContainer } from '@layouts/DefaultContainer'
import { Home } from '@pages/Home'
import { Checkout } from '@pages/Checkout'
import { settings } from '@pages/settings'
import { Success } from '@pages/Checkout/Success'
import { Error } from '@pages/Checkout/Error'
import { Cart } from '@pages/Cart'
import { Profile } from '@pages/Profile'

export function Router() {
  const { initialized } = useKeycloak()

  if (!initialized) return null

  return (
    <BrowserRouter>
      <RouterGuard>
        <Route path="/profile" element={<DefaultContainer {...settings.profile} />}>
          <Route path="/profile" element={<Profile />} />
        </Route>
        <Route path="/cart" element={<DefaultContainer {...settings.cart} />}>
          <Route path="/cart" element={<Cart />} />
        </Route>
        <Route path="/checkout" element={<DefaultContainer {...settings.checkout} />}>
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/checkout/success" element={<Success />} />
          <Route path="/checkout/error" element={<Error />} />
        </Route>
        <Route path="/" element={<DefaultContainer {...settings.home} />}>
          <Route path="/" element={<Home />} />
        </Route>
        <Route path="/" element={<DefaultContainer {...settings.home} />}>
          <Route path="/edit/:timestamp" element={<Home />} />
        </Route>
      </RouterGuard>
    </BrowserRouter>
  )
}
