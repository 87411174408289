export const config = {
  firstStep: {
    count: '1/2',
    title: 'Dados do produto',
    icon: 'drug-pill',
    tooltipLabel: 'Informe os dados referente à molécula simples ou composta, que deseja destacar no nosso catálogo.'
  },
  secondStep: {
    count: '2/2',
    title: 'Ciclo e posição',
    icon: 'calendar',
    tooltipLabel: 'Informe o período e qual posição deseja destacar as moléculas no nosso catálogo'
  }
}
