import {
  createContext, PropsWithChildren, useContext, useEffect, useMemo, useState
} from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { KeycloakProfile } from 'keycloak-js'

interface KeycloakCTX {
  profile: KeycloakProfile | undefined;
  token: string;
}

const KeycloakContext = createContext<KeycloakCTX>({} as KeycloakCTX)

export function AdWordKeycloakProvider({ children }: PropsWithChildren) {
  const { initialized, keycloak } = useKeycloak()

  const [profile, setProfile] = useState<KeycloakProfile>()
  const [token, setToken] = useState('')

  useEffect(() => {
    async function init() {
      if (initialized && keycloak.authenticated) {
        setProfile(await keycloak.loadUserProfile())
        setToken(keycloak.token as string)
      }
    }
    init()
  }, [initialized, keycloak])

  keycloak.onAuthSuccess = () => {
    keycloak.loadUserProfile().then((value) => {
      setToken(keycloak.token as string)
      setProfile(value)
    })
  }

  keycloak.onTokenExpired = () => {
    keycloak
      .updateToken(-1)
      .then(() => {
        setToken(keycloak.token as string)
      })
  }

  const value = useMemo(() => ({
    token, profile
  }), [token, profile])

  return (
    <KeycloakContext.Provider value={value}>
      {children}
    </KeycloakContext.Provider>
  )
}

export const useAdWordkeycloak = () => {
  const { token, profile } = useContext(KeycloakContext)
  return { token, profile }
}
