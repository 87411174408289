/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import { INITIAL_STATE, useAdWordStore } from '@store/useAdWordStore'
import { useEffect, useState } from 'react'
import AdsRepository from '@services/api/AdsRepository'
import { Season } from '@components/Seasons/Season'
import { Box } from '@memed/epiderme'
import { useCartStore } from '@store/useCartStore'
import { useLocation } from 'react-router-dom'
import { useMoleculeStore } from '@store/useMoleculesStore'
import { Center, Spinner } from '@chakra-ui/react'
import { scrollTo } from '@utils/scrollTo'
import { IHarvest, ISeasonSelected } from '@interfaces/Harvest'

export const Seasons = () => {
  const [isLoading, setIsLoading] = useState(false)
  const ingredientType = useAdWordStore((state) => state.type)
  const harvests = useAdWordStore((state) => state.harvests)
  const setHarvests = useAdWordStore((state) => state.setHarvests)
  const presentation = useAdWordStore((state) => state.presentation)
  const seasonsSelected = useAdWordStore((state) => state.selectedHarvest)
  const molecules = useMoleculeStore((state) => state.molecules)
  const setSeasonSelected = useAdWordStore((state) => state.setSelectedHarvest)
  const cart = useCartStore((state) => state.cart)

  const { pathname } = useLocation()
  const cartItemTimestamp = pathname.split('/')[2]

  const getAdsByComposition = async () => {
    setIsLoading(true)
    try {
      const response = await AdsRepository.getAvailability({
        type: ingredientType,
        compositions: molecules,
        external_id: `${presentation.id}`
      })
      setHarvests(response)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (presentation.id) {
      getAdsByComposition()
      return
    }
    setHarvests(INITIAL_STATE.harvests)
  }, [presentation, pathname])

  useEffect(() => {
    const itemToBeEdited = cart.find((item) => item.timestamp.toString() === cartItemTimestamp)

    if (itemToBeEdited?.harvests) {
      const harvestsMap = harvests.reduce((map, harvest) => {
        map[harvest.id] = harvest
        return map
      }, {} as { [key: number]: IHarvest })

      const selectedSeasons = itemToBeEdited.harvests.reduce((result, harvestSelected) => {
        const available = harvestsMap[harvestSelected.id]?.available

        if (available) {
          result.push({
            checked: true,
            positionId: harvestSelected.position,
            positionIndexSelected: harvestSelected.position,
            seasonId: harvestSelected.id
          })
        }

        return result
      }, [] as ISeasonSelected[])

      setSeasonSelected(selectedSeasons)
    }
    scrollTo(313)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [harvests])

  /**
   * Business:
   * Com base no ciclo e posição selecionada, desabilita a mesma posição
   * no ciclo anterior e no próximo ciclo
   */
  const shouldShowAlert = (index: number) => {
    const currentSeasonIndex = seasonsSelected[index]?.positionIndexSelected
    const prevSeasonIndex = seasonsSelected[index - 1]?.positionIndexSelected

    if (currentSeasonIndex) {
      if (currentSeasonIndex === prevSeasonIndex) {
        return true
      }
    }
    return false
  }

  return (
    <Box>
      {isLoading && (
        <Center height="14rem">
          <Spinner />
        </Center>
      )}
      {!isLoading && harvests.length > 0 && harvests.slice(0, 5)
        .map((season, index) => {
          const shouldShowAlertByPosition = shouldShowAlert(index)
          return (
            <Season
              season={season}
              shouldShowAlertByPosition={shouldShowAlertByPosition}
              key={season.id}
            />
          )
        })}
    </Box>
  )
}
