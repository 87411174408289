import Keycloak from 'keycloak-js'
import { AUTH_URL, CLIENT_ID, KEYCLOAK_REALM } from '@configs/env'

export const keycloak = new Keycloak({
  url: AUTH_URL,
  realm: KEYCLOAK_REALM,
  clientId: CLIENT_ID
})

export const keycloakProviderInitConfig: Keycloak.KeycloakInitOptions = {
  onLoad: 'check-sso'
}
