/* eslint-disable arrow-body-style */
import {
  Box,
  Checkbox,
  Text,
  Icon,
  RadioButton,
  useToast,
  Tooltip
} from '@memed/epiderme'
import { Collapse, RadioGroup } from '@chakra-ui/react'
import { useAdWordStore } from '@store/useAdWordStore'
import { IHarvest } from '@interfaces/Harvest'
import { useEffect } from 'react'
import { formatCurrency } from '../../../utils/currency'
import { formatDate } from '../../../utils/dateTime'

type ISeason = {
  season: IHarvest;
  shouldShowAlertByPosition: boolean;
}

export function Season({ season, shouldShowAlertByPosition }: ISeason) {
  const seasonsSelected = useAdWordStore((state) => state.selectedHarvest)
  const setSeasonsSelected = useAdWordStore((state) => state.setSelectedHarvest)

  const toast = useToast()

  useEffect(() => {
    if (shouldShowAlertByPosition) {
      toast({
        description: `Você selecionou a mesma posição em dois ciclos seguidos.
        Por conta disso, será necessária uma aprovação do nosso time comercial para confirmar a reserva.`,
        position: 'bottom-left',
        isClosable: true,
        duration: 6000
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowAlertByPosition])

  const seasonSelected = seasonsSelected.find((s) => s.seasonId === +season.id)

  const handlePosition = (position: string) => {
    const seasonFound = seasonsSelected.find((s) => s.seasonId === +season.id)
    if (seasonFound) {
      seasonFound.positionId = +position
      seasonFound.positionIndexSelected = season.positions.find((p) => p.id === +position)?.index
      const newSeasonsSelected = seasonsSelected.filter((s) => s.seasonId !== +season.id)
      const sortedSeason = [...newSeasonsSelected, seasonFound].sort((a, b) => a.seasonId - b.seasonId)
      setSeasonsSelected(sortedSeason)
    }
  }

  const handleSeason = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.checked) {
      setSeasonsSelected([...seasonsSelected, {
        checked: target.checked,
        seasonId: +target.value,
        positionId: 0
      }])
    } else {
      const newSeasonsSelected = seasonsSelected.filter((s) => s.seasonId !== +target.value)
      setSeasonsSelected(newSeasonsSelected)
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="start"
      mt={8}
      ml="1.2rem"
      maxW="22.5rem"
    >
      {!season.available ? (
        <Tooltip
          placement="right"
          labelContent="A apresentação selecionada já foi reservada para esse ciclo"
        >
          <Checkbox
            size="lg"
            isChecked={!!seasonSelected?.checked}
            isDisabled={!season.available}
            value={season.id}
            onChange={handleSeason}
            alignSelf="start"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="1.5rem"
            >

              <Text
                as="p"
                fontWeight="normal"
                fontSize="md"
                letterSpacing=""
                color="neutral.800"
              >

                {`${season.name}: ${formatDate(season.startedAt)} à ${formatDate(season.endedAt)}`}

              </Text>
              {seasonSelected?.checked
                ? (<Icon icon="chevron-down" color="neutral.800" />)
                : (<Icon icon="chevron-right" color="neutral.800" />)}
            </Box>
          </Checkbox>
        </Tooltip>
      ) : (
        <Checkbox
          size="lg"
          isChecked={!!seasonSelected?.checked}
          isDisabled={!season.available}
          value={season.id}
          onChange={handleSeason}
          alignSelf="start"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="1.5rem"
          >

            <Text
              as="p"
              fontWeight="normal"
              fontSize="md"
              letterSpacing=""
              color="neutral.800"
            >

              {`${season.name}: ${formatDate(season.startedAt)} à ${formatDate(season.endedAt)}`}

            </Text>
            {seasonSelected?.checked
              ? (<Icon icon="chevron-down" color="neutral.800" />)
              : (<Icon icon="chevron-right" color="neutral.800" />)}
          </Box>
        </Checkbox>
      )}
      <Box
        mt="0.5rem"
        ml="1.2rem"
        alignSelf="start"
      >
        <Collapse in={!!seasonSelected?.checked}>
          <RadioGroup
            display="flex"
            flexDirection="column"
            justifyContent="start"
            p="0.5rem"
            w="100%"
            gap="0.75rem"
            onChange={handlePosition}
            value={seasonSelected?.positionId}
          >
            {season.positions.map((pos) => (
              !pos.available ? (
                <Tooltip
                  placement="right"
                  labelContent="Posição indisponível"
                  key={pos.id}
                >
                  <RadioButton
                    size="lg"
                    value={pos.id}
                    isRequired={!!seasonSelected?.checked}
                    isDisabled={!pos.available || !season.available}
                  >
                    <Text
                      textDecor={pos.available ? 'none' : 'line-through'}
                    >
                      {`${pos.name} - ${formatCurrency({ value: pos.value })}`}
                    </Text>
                  </RadioButton>
                </Tooltip>
              ) : (
                <RadioButton
                  size="lg"
                  key={pos.id}
                  value={pos.id}
                  isRequired={!!seasonSelected?.checked}
                  isDisabled={!pos.available}
                >
                  <Text
                    textDecor={pos.available ? 'none' : 'line-through'}
                  >
                    {`${pos.name} - ${formatCurrency({ value: pos.value })}`}
                  </Text>
                </RadioButton>
              )
            ))}
          </RadioGroup>
        </Collapse>
      </Box>
    </Box>
  )
}
