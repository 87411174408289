import { useKeycloak } from '@react-keycloak/web'
import { Navigate } from 'react-router-dom'

export const Login = () => {
  const { keycloak } = useKeycloak()

  if (keycloak?.authenticated) {
    return <Navigate to="/" />
  }
  keycloak?.login()
  return null
}
