import {
  createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useMemo, useState
} from 'react'
import {
  SeasonSelected,
  SetSeasons
} from '@components/Seasons/Season/Season.interface'
import { IHarvest } from '@interfaces/Harvest'

interface AdWordContextProps {
  seasonsSelected: SeasonSelected[]
  setSeasonsSelected: Dispatch<SetStateAction<SeasonSelected[]>>
  seasons: IHarvest[]
  setSeasons: SetSeasons
}

const AdWordContext = createContext({} as AdWordContextProps)

const AdWordProvider = ({ children }: PropsWithChildren) => {
  const [seasonsSelected, setSeasonsSelected] = useState<SeasonSelected[]>([])
  const [seasons, setSeasons] = useState<IHarvest[]>([])

  const value = useMemo(() => ({
    seasonsSelected,
    setSeasonsSelected,
    seasons,
    setSeasons
  }), [seasonsSelected, seasons])
  return (
    <AdWordContext.Provider value={value}>
      {children}
    </AdWordContext.Provider>
  )
}

const useAdWord = (): AdWordContextProps => {
  const context = useContext(AdWordContext)

  if (!context) {
    throw new Error('useAdWord must be used within an AdWordProvider')
  }

  return context
}

export { useAdWord, AdWordProvider }
