import { Divider } from '@chakra-ui/react'
import { Cart } from '@components/Cart'
import { CartItem } from '@components/CartItem'
import {
  Flex,
  IconFeatured,
  Text,
  Heading,
  Box
} from '@memed/epiderme'
import { useCartStore } from '@store/useCartStore'
import { useHarvestsErrorsStore } from '@store/useHarvestsErrorsStore'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const Error = () => {
  const cart = useCartStore((state) => state.cart)
  const { harvestsErrors, actions: { clear } } = useHarvestsErrorsStore((state) => state)
  const navigate = useNavigate()

  useEffect(() => {
    if (!harvestsErrors.length || !cart.length) {
      clear()
      navigate('/cart')
    }
  }, [harvestsErrors, cart, navigate, clear])

  return harvestsErrors.length ? (
    <Flex flexDir="column" gap={4}>
      <Flex
        border="1px"
        borderColor="neutral.200"
        borderRadius="8px"
        flexDir="column"
        alignItems="center"
        p={6}
        gap={6}
      >
        <Flex flexDir="column" alignItems="center" gap={2}>
          <Box transform="rotate(45deg)">
            <IconFeatured icon="circle-plus" size={20} colorScheme="danger" />
          </Box>
          <Text color="danger.600" fontWeight="medium" lineHeight="base">Solicitação não concluída</Text>
        </Flex>
        <Text color="neutral.800" lineHeight="6">
          Sua solicitação não pode ser concluída, pois os produtos listados não estão mais disponíveis para o período
          e/ou posição selecionada. Por favor,  verifique outras opções.
        </Text>
        <Heading
          as="h2"
          type="title-sm"
          fontWeight="medium"
          color="neutral.900"
          lineHeight="shorter"
          width="100%"
        >
          Lista de produtos não disponíveis
        </Heading>
        <Divider borderColor="neutral.500" />
        <Box width="100%">
          {cart.length > 0 && (
          <Cart>
            {cart.map((item) => (<CartItem item={item} key={item.timestamp} />))}
          </Cart>
          )}
        </Box>
        {/* <Flex gap={6}>
        <Button size="sm" px={4}>Nova contratação</Button>
      </Flex> */}
      </Flex>
    </Flex>
  ) : null
}
